<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.settings')"
    />

    <v-list two-line class="my-2 py-0">
      <v-list-item
        v-for="(item, index) in data"
        :key="index"
        @click="$router.push({ path: item.path })"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
      >
        <v-list-item-avatar>
          <v-icon :class="item.color" dark>{{ item.icon }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon
            ><v-icon color="grey lighten-1">mdi-chevron-right</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import store from "@/store";
import PageHeader from "@/components/ui/PageHeader";
export default {
  name: "Settings",

  components: {
    PageHeader,
  },

  data() {
    return {
      pageTitle: this.$t("settings"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [
        {
          name: "changePassword",
          path: "/settings/change-password",
          icon: "mdi-security",
          color: "light-green",
        },
      ], // wrapper for fetched data
    };
  },

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);
  },
};
</script>
